import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import auth_store, { PURGE_AUTH } from "./store/auth.module";
import router from "../../router";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(Vuex);
    let store = new Vuex.Store(auth_store);

    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = "https://pcdapi.com/api/";
    // Vue.axios.defaults.baseURL = "https://dev.pcdapi.com/api/";
    // Vue.axios.defaults.baseURL = "http://woostore.test/api/";

    Vue.axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status !== 401) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
        store.commit(PURGE_AUTH);
        router.push({ name: "login" });

        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${
      JwtService.getUser().access_token
    }`;
  },

  request(config) {
    return Vue.axios.request(config);
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  get(resource, params = []) {
    return Vue.axios
      .get(`${resource}`, {
        params: params
      })
      .catch(error => {
        // console.log(error);
        throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
